
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";

export default function Page404() {
  const { t } = useTranslation("not-found");

  return (
    <div className="flex-center h-screen w-screen flex-col gap-8 bg-grey-300">
      <h1 className="text-9xl font-semibold">404</h1>
      <p className="text-3xl">{t`title`}</p>
      <Link href="/">
        <a className="button-text text-lg text-blue">{t`return-to-vialantis`}</a>
      </Link>
      <Link href="/">
        <a>
          <Image
            src={`${process.env.NEXT_PUBLIC_STORAGE_URL}/img/vialantis-logo.svg`}
            alt="Vialantis Logo"
            width={161}
            height={41}
          />
        </a>
      </Link>
    </div>
  );
}


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  